import { h } from 'preact';

const Logo = () => (
  <svg class="playlist-track-icon" version="1.1" viewBox="0 0 1024 1024" preserveAspectRatio="xMinYMin meet">
    <path d="M514,963C265.9,963,64,761.1,64,513C64,264.9,265.9,63,514,63c248.1,0,450,201.9,450,450C964,761.1,762.1,963,514,963z M514,155.4c-197.2,0-357.6,160.4-357.6,357.6c0,197.2,160.4,357.6,357.6,357.6c197.2,0,357.6-160.4,357.6-357.6C871.6,315.8,711.2,155.4,514,155.4z"/>
    <path d="M513.3,716.5c-11.8,0-23.7-4.5-32.7-13.5L323.3,545.7c-8.7-8.7-13.5-20.4-13.5-32.7c0-12.3,4.9-24,13.5-32.7l157.3-157.3c18-18,47.3-18.1,65.4,0c18,18.1,18,47.3,0,65.4L421.4,513l124.6,124.6c18,18,18,47.3,0,65.4C536.9,712,525.1,716.5,513.3,716.5z"/>
  </svg>
);

export default Logo;
